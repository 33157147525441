import React from 'react';
import HeaderComponent from '../components/Header'; 
import FooterComponent from '../components/Footer'; 
import SideNavbarComponent from '../components/SideNavbar';
import {Box,Container,Paper} from '@mui/material';
import { useState, useEffect } from "react"; 
import { loadStripe } from "@stripe/stripe-js"; 
import '../App.css';
import { collection, addDoc, getDocs } from "firebase/firestore";
import {db} from '../firebase';

const breadcrumbs = ['Home', 'Catalog Store', 'Air Law and Operational Procedures GCAA'];
 

export const SampleQuestions = () => {
  const [questions , setQuestions] = useState([]);

  const fetchQuestions = async () => {
    console.log("fetching questions");
       
    await getDocs(collection(db, "questions"))
        .then((querySnapshot)=>{               
            const newData = querySnapshot.docs
                .map((doc) => ({...doc.data(), id:doc.id }))
                .slice(0, 3);
              console.log(newData);
              setQuestions(newData);                
            console.log(questions, newData);
        })
   
}

useEffect(()=>{
  console.log("coming to set effect");
  const items = JSON.parse(localStorage.getItem('items'));
    if (items) {
     setItems(items);
    }
  fetchQuestions();
}, [])
   
    const [anchorEl, setAnchorEl] = React.useState(null);
  
    const handleAccountClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleMenuClose = () => {
      setAnchorEl(null);
    };

    const [selectedOption, setSelectedOption] = useState('');

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const prevQn = async () => { 
    await setCardIdx(cardIdx-1);
  }

  const nextQn = async () => { 
    await setCardIdx(cardIdx+1);
  }


  const [cardIdx, setCardIdx] = useState(0);

  const [items, setItems] = useState([]);

  const [product, setProduct] = useState({ 
    name: "Air Law and Operational Procedures GCAA", 
    price: 1000, 
    productOwner: "Aviation Site", 
    description: 
      "The subject Air Law and Operational Procedures GCAA contains 1155 questions and 18 Subcategories. It is part of the BUNDLE: Air Law and Operational Procedures GCAA + B777 Type Rating package.", 
    quantity: 1, 
  }); 

  const makePayment = async () => { 
    const stripe = await loadStripe("pk_test_51NVzOHSEccTJVT5G27q5UDJ7BLFW5HHqUqnKalMTMwqWaObXrSOvugGy42IQlT97LEVAwDPOlRoF1dPtOAs33vU500CiMdEqJ4"); 
    const body = { product }; 
    const headers = { 
      "Content-Type": "application/json", 
    }; 
 
    const response = await fetch( 
      "http://localhost:8000/api/create-checkout-session", 
      { 
        method: "POST", 
        headers: headers, 
        body: JSON.stringify(body), 
      } 
    ); 
 
    const session = await response.json(); 
    let items = [
        {
          id: 1,
          title: 'AIR LAW AND OPERATIONAL PROCEDURES GCAA',
          imageUrl: 'https://via.placeholder.com/150',
        },
      ]
    localStorage.setItem('items', JSON.stringify(items));
 
    const result = stripe.redirectToCheckout({ 
      sessionId: session.id, 
    }); 
 
    if (result.error) { 
      console.log(result.error); 
    } 
  };  
  
    return (
      <>
        {/* Header */}
        <HeaderComponent
          handleAccountClick={handleAccountClick}
          handleMenuClose={handleMenuClose}
          anchorEl={anchorEl}
        />
        
        {/* SideNavbar */}
        <SideNavbarComponent />
  
        {/* Content */}
        <Container sx={{ flexGrow: 1,  display: 'flex' }}>
            <Box sx={{ flex: 1 }}>
                <Paper sx={{ p: 2, height: '85vh', borderRadius: 0, paddingTop: '30px !important' }}>
                    <div className="CardList">
                        <div className="Breadcrumbs">
                            {breadcrumbs.map((breadcrumb, index) => (
                                <React.Fragment key={index}>
                                    <span className={index === breadcrumbs.length - 1 ? 'ActiveBreadcrumb' : 'Breadcrumb'}>
                                        {breadcrumb}
                                    </span>
                                    {index !== breadcrumbs.length - 1 && <span className="BreadcrumbSeparator">/</span>}
                                </React.Fragment>
                            ))}
                        </div>
                        {cardIdx < 3 ? (
                            <div className="Column Column1">
                                <div className="SubCard" key={questions[cardIdx]?.id}>
                                    <div className="CardTitle">Question {questions[cardIdx]?.qid}</div>
                                    <hr/>
                                    <div className="CardTitle"><p>{questions[cardIdx]?.text}</p></div>
                                    <div className="RadioButtons">
                                        <label className="RadioButton">
                                            <input
                                                type="radio"
                                                value={questions[cardIdx]?.op_a}
                                                checked={selectedOption === 'option1'}
                                                onChange={handleOptionChange}
                                            />
                                            {questions[cardIdx]?.op_a}
                                        </label>
                                        <label className="RadioButton">
                                            <input
                                                type="radio"
                                                value={questions[cardIdx]?.op_b}
                                                checked={selectedOption === 'option2'}
                                                onChange={handleOptionChange}
                                            />
                                            {questions[cardIdx]?.op_b}
                                        </label>
                                        <label className="RadioButton">
                                            <input
                                                type="radio"
                                                value={questions[cardIdx]?.op_c}
                                                checked={selectedOption === 'option3'}
                                                onChange={handleOptionChange}
                                            />
                                            {questions[cardIdx]?.op_c}
                                        </label>
                                        <label className="RadioButton">
                                            <input
                                                type="radio"
                                                value={questions[cardIdx]?.op_d}
                                                checked={selectedOption === 'option4'}
                                                onChange={handleOptionChange}
                                            />
                                            {questions[cardIdx]?.op_d}
                                        </label>
                                    </div>  
                                    <div className="CardFooter">
                                        <div className="CardLink">{questions[cardIdx]?.link}</div>
                                        <div className="CardText"><button className='subscribe' onClick={nextQn}>Submit</button></div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="SubscribeContainer" style={{ marginTop: '50px' }}>
                                <p>Subscribe for more questions</p>
                                <button className="subscribe" onClick={makePayment}>Subscribe</button>
                            </div>
                        )}
                        <div className="Column Column2">
                            {cardIdx < 3 && (
                                <>
                                    <button className='subscribe' style={{ marginRight: 20}} onClick={prevQn}>Previous</button>
                                    <button className='subscribe' onClick={nextQn}>Next</button>
                                </>
                            )}
                        </div>
                    </div>
                </Paper>
            </Box>
        </Container>
  
        {/* Footer */}
        <FooterComponent />
      </>
    );
  };
  
  export default SampleQuestions;
  