import { useState, useEffect } from "react"; 
import React from 'react';
import HeaderComponent from '../components/Header'; 
import FooterComponent from '../components/Footer'; 
import SideNavbarComponent from '../components/SideNavbar';
import { SelectTopics } from "./SelectTopics"
import {Box,Container,Paper} from '@mui/material';
import '../App.css';
import { faHome, faPlane, faCartShopping, faContactCard, faTrash, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const trainingData = [
];

const data = [
  { value: 25, label: 'Answered' },
  { value: 30, label: 'Not answered' },
  { value: 15, label: 'Incorrectly answered' },
];

const size = {
  width: 400,
  height: 200,
};


const cardData = [
  {
    id: 1,
    title: 'AIR LAW AND OPERATIONAL PROCEDURES',
    imageUrl: 'https://via.placeholder.com/150',
  },
  {
    id: 2,
    title: 'AIR AND DEMO OPERATIONAL PROCEDURES',
    imageUrl: 'https://via.placeholder.com/150',
  },
  {
    id: 3,
    title: 'AIR AND GGCA OPERATIONAL PROCEDURES',
    imageUrl: 'https://via.placeholder.com/150',
  },
];

const tableData = [
    {
      catalog: 'Air Law and Operational Procedures GCAA',
      subjects: 'Active',
      questions: 8109,
      action: 'Select',
    },
    {
      catalog: 'CAAC Chinese ATPL',
      subjects: 'Active',
      questions: 1155,
      action: 'Select',
    },
  ];

const breadcrumbs = ['Home', 'Training Sessions'];
 

export const TrainingSessionListPage = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);
  
    const handleAccountClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleMenuClose = () => {
      setAnchorEl(null);
    };

    const [selectedOption, setSelectedOption] = useState('');

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const prevQn = async () => { 
    await setCardIdx(cardIdx-1);
  }

  const nextQn = async () => { 
    await setCardIdx(cardIdx+1);
  }

  const [pieData, setPieData] = useState([
]);

useEffect(() => {
      setPieData([
        ])
  }, [pieData])


  const [cardIdx, setCardIdx] = useState(0);

    const [items, setItems] = useState([]);
  useEffect(() => {
    const items = JSON.parse(localStorage.getItem('items'));
    if (items) {
     setItems(items);
    }
  }, []);
  
    return (
      <>
        {/* Header */}
        <HeaderComponent
          handleAccountClick={handleAccountClick}
          handleMenuClose={handleMenuClose}
          anchorEl={anchorEl}
        />
        
        {/* SideNavbar */}
        <SideNavbarComponent />
  
        {/* Content */}
        <Container sx={{ flexGrow: 1,  display: 'flex'}}>
          <Box sx={{ flex: 1  }}>
            <Paper sx={{ p: 2, height: '95vh', borderRadius: 0, paddingTop: '30px !important' }}>
            <div className="CardList">
        <div className="Breadcrumbs">
          {breadcrumbs.map((breadcrumb, index) => (
            <React.Fragment key={index}>
              <span className={index === breadcrumbs.length - 1 ? 'ActiveBreadcrumb' : 'Breadcrumb'}>
                {breadcrumb}
              </span>
              {index !== breadcrumbs.length - 1 && <span className="BreadcrumbSeparator">/</span>}
            </React.Fragment>
          ))}
        </div>
        <div className='tsContainer'>
        <div className="TableContainer">
          <table className="Table">
            <thead>
              <tr>
                <th>Category</th>
                <th>Status</th>
                <th>Questions</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((row, index) => (
                <tr key={index}>
                  <td>{row.catalog}</td>
                  <td>{row.subjects}</td>
                  <td>{row.questions}</td>
                  <td><button onClick={event =>  window.location.href='/training-session-2'}>{row.action}</button></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="trainingPieContainer"> 
        <h5 className='tsPieHeading'>Air Law and Operational Procedures GCAA</h5>
<PieChart
      colors={['#2CC388', '#0096FF', '#E43425']}
      series={[
        {
          arcLabelMinAngle: 45,
          data,  
        },
      ]}
      sx={{
        [`& .${pieArcLabelClasses.root}`]: {
          fill: 'white',
          fontWeight: 'bold',
        },
      }}
      {...size}
    />
    <hr className='tsHr' />
    <div className="cntr"><button className="tsOpenSessionBtn" onClick={event =>  window.location.href='/training-session'}>Open Session</button></div>
    <div className="cntr"><button className="tsOpenSessionBtnDel" onClick={event =>  window.location.href='/catalog-item'}><FontAwesomeIcon icon={faTrash} /> Delete Session</button></div>
    </div>
        </div>

        </div>
            </Paper>
          </Box>
        </Container>
  
        {/* Footer */}
        <FooterComponent />
      </>
    );
  };
  
  export default TrainingSessionListPage;
  