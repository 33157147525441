import React from 'react';
import {
    Home as HomeIcon,
    Category as CatalogIcon,
    Subscriptions as SubscriptionsIcon,} from '@mui/icons-material';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import SideNav, { Toggle, Nav, NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import { useLocation , useNavigate  } from 'react-router-dom';

const SideNavbarComponent = () => {
    const location = useLocation();
    const navigate = useNavigate ();

    const getSelected = (pathname) => {
        if (pathname === '/') return 'home';
        if (pathname.startsWith('/catalog')) return 'catalog';
        if (pathname.startsWith('/subscriptions')) return 'subscriptions';
        if (pathname.startsWith('/training-sessions')) return 'training-sessions';
        return 'home'; // default
    };
    return (
        <SideNav className="side-nav" onSelect={(selected) => {
            console.log('clicked on ', selected);
            // if(selected === 'home') {
            //     window.location.href=`/`
            // }
            // else{
            //     window.location.href=`/${selected}`
            // }
            navigate(selected === 'home' ? '/' : `/${selected}`);
        }}>
            <SideNav.Toggle />
                <SideNav.Nav defaultSelected={getSelected(location.pathname)}>
                <NavItem eventKey="home">
                    <NavIcon>
                        <HomeIcon />
                    </NavIcon>
                    <NavText>
                        Home
                    </NavText>
                </NavItem>
                <NavItem eventKey="catalog">
                    <NavIcon>
                        <CatalogIcon />
                    </NavIcon>
                    <NavText>
                        Catalog
                    </NavText>
                    {/* Additional NavItems */}
                </NavItem>
                <NavItem eventKey="subscriptions">
                    <NavIcon>
                        <SubscriptionsIcon />
                    </NavIcon>
                    <NavText>
                        Subscriptions
                    </NavText>
                </NavItem>
                <NavItem eventKey="training-sessions">
                    <NavIcon>
                        <FlightTakeoffIcon />
                    </NavIcon>
                    <NavText>
                        Training Sessions
                    </NavText>
                </NavItem>
            </SideNav.Nav>
        </SideNav>
    );
};


export default SideNavbarComponent;
    