import React from 'react';
import HeaderComponent from '../components/Header'; 
import FooterComponent from '../components/Footer'; 
import SideNavbarComponent from '../components/SideNavbar';
import { SelectTopics } from "./SelectTopics"
import {Box,Container,Paper} from '@mui/material';

const SelectTopicsPage = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);
  
    const handleAccountClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleMenuClose = () => {
      setAnchorEl(null);
    };
  
    return (
      <>
        {/* Header */}
        <HeaderComponent
          handleAccountClick={handleAccountClick}
          handleMenuClose={handleMenuClose}
          anchorEl={anchorEl}
        />
        
        {/* SideNavbar */}
        <SideNavbarComponent />
          
        {/* Content */}
        <Container sx={{ flexGrow: 1,  display: 'flex' }}>
          <Box sx={{ flex: 1 }}>
            <Paper sx={{ p: 2, height: '85vh', borderRadius: 0, paddingTop: '30px !important' }}>
              <SelectTopics />
            </Paper>
          </Box>
        </Container>
  
        {/* Footer */}
        <FooterComponent />
      </>
    );
  };
  
  export default SelectTopicsPage;
