 // Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

import { getAuth } from 'firebase/auth';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyCygpaEDfKffB5bPvJ4rAKrv-QPdRPB4pc",
    authDomain: "avia-cc8a6.firebaseapp.com",
    projectId: "avia-cc8a6",
    storageBucket: "avia-cc8a6.appspot.com",
    messagingSenderId: "606018202444",
    appId: "1:606018202444:web:8d1a9b3aef5314e8efbde9"
  };
// Initialize Firebase

export const app = initializeApp(firebaseConfig);
// Export firestore database
// It will be imported into your react app whenever it is needed
export const auth = getAuth(app);

export const db = getFirestore(app);