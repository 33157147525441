
import React, { useState } from 'react';
import HeaderComponent from '../components/Header';
import FooterComponent from '../components/Footer';
import SideNavbarComponent from '../components/SideNavbar';
import CircularIndeterminate from '../components/Loader';
import { Box, Container, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { collection, addDoc, getDocs, query } from "firebase/firestore";
import { db, auth } from '../firebase';
import { loadStripe } from "@stripe/stripe-js";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import '../App.css';

const breadcrumbs = ['Home', 'Catalog Store'];

const CatalogPage = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [currentUser, setCurrentUser] = React.useState(null);
  const [courses, setCourses] = React.useState([]);
  const [loading, setLoading] = React.useState(false); // Add loading state

  const fetchCourses = async () => {
    const q = query(collection(db, "courses"));
    const querySnapshot = await getDocs(q);
    const newData = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    setCourses(newData);
  };

  React.useEffect(() => {
    fetchCourses();
  }, []);

  React.useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setCurrentUser(user);
    });
    return () => unsubscribe();
  }, []);

  const handleAccountClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const makePayment = async (course_uid) => {
    setLoading(true); // Show loader
    const stripe = await loadStripe("pk_test_51NVzOHSEccTJVT5G27q5UDJ7BLFW5HHqUqnKalMTMwqWaObXrSOvugGy42IQlT97LEVAwDPOlRoF1dPtOAs33vU500CiMdEqJ4");
    const product = {
      name: "UAE AIR LAW AND OPERATIONAL PROCEDURES GCAA",
      price: 1999,
      currency: 'usd',
      productOwner: "Aviation Site",
      description:
        "The subject UAE AIR LAW AND OPERATIONAL PROCEDURES GCAA contains 1155 questions and 18 Subcategories. It is part of the BUNDLE: UAE AIR LAW AND OPERATIONAL PROCEDURES GCAA + B777 Type Rating package.",
      quantity: 1,
      course_uid: "z4bq1AYOc0z3G51ItSUD"
    };

    const response = await fetch(
      `https://api.stapl.io/api/create-checkout-session?user_uid=${currentUser.uid}&course_uid=${course_uid}`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ product }),
      }
    );

    const session = await response.json();

    const result = stripe.redirectToCheckout({ sessionId: session.id });

    if (result.error) {
      console.error(result.error);
      setLoading(false); // Hide loader in case of error
      return;
    }

    let items = [
      {
        id: 1,
        title: 'UAE AIR LAW AND OPERATIONAL PROCEDURES GCAA',
        imageUrl: 'https://via.placeholder.com/150',
      },
    ];
    localStorage.setItem('items', JSON.stringify(items));

    await addDoc(collection(db, "subscriptions"), {
      user_uid: currentUser.uid,
      course_uid: course_uid,
      subscribed_at: new Date().toISOString(),
      is_active: true,
      title: 'UAE AIR LAW AND OPERATIONAL PROCEDURES GCAA',
      imageUrl: 'https://via.placeholder.com/150'
    });

    setLoading(false); // Hide loader after successful subscription
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const cellStyles = {
    fontSize: {
      xs: '0.75rem',
      sm: '0.875rem',
      md: '1rem'
    },
    width: {
      xs: '100%',
      sm: '25%',
      md: '25%'
    },
    headerBgColor: {
      backgroundColor: '#4A0404',
      color: 'white !important',
      fontWeight: 'bold'
    }
  };

  return (
    <>
      {loading && (
        <div className="loader-container">
          <CircularIndeterminate />
        </div>
      )}
      <HeaderComponent
        handleAccountClick={handleAccountClick}
        handleMenuClose={handleMenuClose}
        anchorEl={anchorEl}
      />
      <SideNavbarComponent />
      <Container sx={{ flexGrow: 1, display: 'flex' }}>
        <Box sx={{ flex: 1 }}>
          <Paper sx={{ p: 2, height: '85vh', borderRadius: 0, paddingTop: '30px !important' }}>
            <div className="Breadcrumbs" style={{ marginLeft: '40px' }}>
              {breadcrumbs.map((breadcrumb, index) => (
                <React.Fragment key={index}>
                  <span className={index === breadcrumbs.length - 1 ? 'ActiveBreadcrumb' : 'Breadcrumb'}>
                    {breadcrumb}
                  </span>
                  {index !== breadcrumbs.length - 1 && <span className="BreadcrumbSeparator">/</span>}
                </React.Fragment>
              ))}
            </div>
            <TableContainer component={Paper} style={{ marginLeft: '40px', marginTop: '20px', maxWidth: '90%' }}>
              <Table>
                <TableHead>
                  <TableRow style={{ ...cellStyles.headerBgColor }}>
                    {isMobile ? (
                      <>
                        <TableCell sx={{ fontSize: cellStyles.fontSize, width: '100%', color: 'white' }}>Course/Topics</TableCell>
                        <TableCell sx={{ fontSize: cellStyles.fontSize, width: '100%', color: 'white' }}>Actions</TableCell>
                      </>
                    ) : (
                      <>
                        <TableCell sx={{ fontSize: cellStyles.fontSize, width: cellStyles.width, color: 'white' }}>Course</TableCell>
                        <TableCell sx={{ fontSize: cellStyles.fontSize, width: cellStyles.width, color: 'white' }}>Topics</TableCell>
                        <TableCell sx={{ fontSize: cellStyles.fontSize, width: cellStyles.width, color: 'white' }}>Questions</TableCell>
                        <TableCell sx={{ fontSize: cellStyles.fontSize, width: cellStyles.width, color: 'white' }}>Actions</TableCell>
                      </>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {courses.map((row, index) => (
                    <TableRow key={index}>
                      {isMobile ? (
                        <>
                          <TableCell sx={{ fontSize: cellStyles.fontSize }}>
                            {row.title}<br />
                            {row.no_topics}
                          </TableCell>
                          <TableCell sx={{ fontSize: cellStyles.fontSize }}>
                            <button onClick={() => makePayment(row.id)}>Subscribe</button>
                            <button onClick={event => window.location.href = '/training-session/4vgV1cTgXi5hm00Udk2k'}>View Sample</button>
                          </TableCell>
                        </>
                      ) : (
                        <>
                          <TableCell sx={{ fontSize: cellStyles.fontSize }}>{row.title}</TableCell>
                          <TableCell sx={{ fontSize: cellStyles.fontSize }}>{row.no_topics}</TableCell>
                          <TableCell sx={{ fontSize: cellStyles.fontSize }}>{row.no_questions}</TableCell>
                          <TableCell sx={{ fontSize: cellStyles.fontSize }}>
                            <button onClick={() => makePayment(row.id)}>Subscribe</button>
                            <button onClick={event => window.location.href = '/training-session/4vgV1cTgXi5hm00Udk2k'}>View Sample</button>
                          </TableCell>
                        </>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      </Container>
      <FooterComponent />
    </>
  );
};

export default CatalogPage;

