import React from "react"; 

export const Success = () => (
  <>
    <h2>Thanks for your order!</h2>
    <h4>Your payment is successful.</h4>
    <p>
      We appreciate your subscription! If you have any questions, please email us
      at <a href="mailto:chaithanya@avationsite.com">chaithanya@avationsite.com</a>.
    </p>
    <div></div>
  </>
);

 
export default Success; 