import React, { useState, useEffect } from "react";
import '../App.css';
import { collection, addDoc, getDocs, query, where, doc, setDoc } from "firebase/firestore";
import { db, app, auth } from '../firebase';
import Alert from '@mui/material/Alert';
import { useLocation } from 'react-router-dom'; // If using React Router
import { format, parseISO } from 'date-fns';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


const cardData = [];

const trainingData = [];

const data = [
  { value: 25, label: 'Answered' },
  { value: 30, label: 'Not answered' },
  { value: 15, label: 'Incorrectly answered' },
];

const size = {
  width: 400,
  height: 200,
};

export const HomeComponent = () => {
  const [items, setItems] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const location = useLocation(); // Get the current location object


  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setCurrentUser(user);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem('items'));
    if (items) {
      setItems(items);
    }
  }, []);

  const [trainingSessions, setTrainingSessions] = useState([]);

  const fetchTrainingSessions = async (user_uid) => {
    console.log("fetching training_sessions");
    const q = query(collection(db, "training_sessions"), where("user_uid", "==", user_uid));

    await getDocs(q)
      .then((querySnapshot) => {
        const trainingSessions = querySnapshot.docs
          .map((doc) => ({ ...doc.data(), id: doc.id }));
        console.log(trainingSessions);
        setTrainingSessions(trainingSessions);
        console.log(trainingSessions, "training sessions");
        localStorage.setItem('training_sessions', JSON.stringify(trainingSessions));
      })

  }

  const subscribeToCourse = async (user_uid, course_uid, sid) => {
    console.log("subscribing to course", user_uid, course_uid, sid);
    const dRef = doc(db, "subscriptions", sid);
    await setDoc(dRef, {
      user_uid: user_uid,
      course_uid: course_uid,
      subscribed_at: new Date().toISOString(),
      is_active: true,
      title: 'UAE AIR LAW AND OPERATIONAL PROCEDURES GCAA',
      imageUrl: 'https://via.placeholder.com/150'
    }, { merge: true });
  }

  useEffect(() => {
    console.log("coming to set effect");
    if (currentUser) {
      console.log(currentUser.uid);
      fetchTrainingSessions(currentUser.uid);
    }
    else {
      console.log(currentUser);
      console.log("user not logged in, redirecting to login page"); // todo: redirect to login page
    }

  }, [currentUser])


  const [subscriptions, setSubscriptions] = useState([]);
  const [shouldShow, setShouldShow] = useState(false);
  const [shouldShowFailed, setShouldShowFailed] = useState(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search); // Parse the search string to get query parameters
    const param = queryParams.get('subscribed'); // Replace 'myParam' with your parameter name
    if (param === 'true') {
      let items = [
        {
          id: 1,
          title: 'UAE AIR LAW AND OPERATIONAL PROCEDURES GCAA',
          imageUrl: 'https://via.placeholder.com/150',
        },
      ]
      localStorage.setItem('items', JSON.stringify(items));
      const user_uid = queryParams.get('user_uid');
      const course_uid = queryParams.get('course_uid');
      const sid = queryParams.get('sid');
      subscribeToCourse(user_uid, course_uid, sid);
      setShouldShow(true); // Set to true if the parameter exists
    } else if (param === 'false') {
      setShouldShowFailed(true); // Set to true if the parameter exists
    }
    else {
      setShouldShow(false); // Set to false if the parameter does not exist
      setShouldShowFailed(false); // Set to false if the parameter does not exist
    }
  }, [location]); // Re-run the effect if the location changes


  const fetchSubscriptions = async (user_uid) => {
    console.log("fetching subscriptions");
    const sixtyDaysAgo = new Date();
    sixtyDaysAgo.setDate(sixtyDaysAgo.getDate() - 60);
    console.log(sixtyDaysAgo.toISOString());
    // fetch subscriptions for the user whose subscribed_at is less than 60 days
    // subscriptions expires in 60 days, so difference from today and subscribed_at should be less than 60 days
    const q = query(collection(db, "subscriptions"), where("user_uid", "==", user_uid), where("subscribed_at", ">", sixtyDaysAgo.toISOString()));
    // const q = query(collection(db, "subscriptions"), where("user_uid", "==", user_uid, "subscribed_at", "<", new Date(Date.now() + 60 * 24 * 60 * 60 * 1000).toISOString()));
    // const q = query(collection(db, "subscriptions"), where("user_uid", "==", user_uid));

    await getDocs(q)
      .then((querySnapshot) => {
        const newData = querySnapshot.docs
          .map((doc) => ({ ...doc.data(), id: doc.id }));
        console.log(newData);
        setSubscriptions(newData);
      })
  }

  useEffect(() => {
    console.log("coming to set effect");
    if (currentUser) {
      console.log(currentUser.uid);
      fetchSubscriptions(currentUser.uid);
    }
    else {
      console.log(currentUser);
      console.log("user not logged in, redirecting to login page"); // todo: redirect to login page
    }

  }, [currentUser])

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Styles that change based on the screen size
  const cellStyles = {
    fontSize: {
      xs: '0.75rem', // for extra-small devices
      sm: '0.875rem', // for small devices
      md: '1rem'     // for medium devices and up
    },
    width: {
      xs: '100%', // single column layout in mobile view
      sm: '25%',  // four columns in desktop view
      md: '25%'
    },
    headerBgColor: {
      backgroundColor: '#4A0404', // color for the table header
      color: 'white !important',             // text color for readability,
      fontWeight: 'bold'
    }
  };

  return (
    <div className="App">
      <main className="MainContent">

        <div className="CardList">
          <h4 style={{ marginLeft: '15px' }}>Active Subscriptions</h4>
          {/* {subscriptions.length > 0 &&
            subscriptions.map((card) => (
              <div className="CustomCard" key={card.id}>
                <img src={card.imageUrl} alt={card.title} />
                <h6>{card.title}</h6>
                <button onClick={event => window.location.href = '/select-topics-page'}>New Session</button>
              </div>
            ))} */}
          <TableContainer component={Paper} style={{ marginLeft: '15px' }}>
            <Table>
              <TableHead>
                <TableRow style={{ ...cellStyles.headerBgColor }}>
                <TableCell sx={{ fontSize: cellStyles.fontSize, width: cellStyles.width, color: 'white' }}>Course</TableCell>
                      <TableCell sx={{ fontSize: cellStyles.fontSize, width: cellStyles.width, color: 'white' }}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {subscriptions.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell sx={{ fontSize: cellStyles.fontSize }}>{isMobile ? <></> : (<img className="subscriptionThumbnail" src={row.imageUrl} alt={row.title} />)} {row.title}</TableCell>
                    <TableCell sx={{ fontSize: cellStyles.fontSize }}><button onClick={event => window.location.href = '/select-topics-page'}>New Session</button></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          {subscriptions.length === 0 &&
            <h5 className='noSubMsg' style={{ marginLeft: '15px' }}>You have no subscriptions</h5>
          }

          <hr className='HSeparator' />

          <h4 style={{ marginLeft: '15px' }}>Current Training In Progress</h4>
          <TableContainer component={Paper} style={{ marginLeft: '15px' }}>
            <Table>
              <TableHead>
                <TableRow style={{ ...cellStyles.headerBgColor }}>
                  {isMobile ? (
                    <TableCell sx={{ fontSize: cellStyles.fontSize, width: '100%', color: 'white' }}>Course/Expiry</TableCell>
                  ) : (
                    <>
                      <TableCell sx={{ fontSize: cellStyles.fontSize, width: cellStyles.width, color: 'white' }}>Course</TableCell>
                      <TableCell sx={{ fontSize: cellStyles.fontSize, width: cellStyles.width, color: 'white' }}>Topics</TableCell>
                      <TableCell sx={{ fontSize: cellStyles.fontSize, width: cellStyles.width, color: 'white' }}>Questions</TableCell>
                      <TableCell sx={{ fontSize: cellStyles.fontSize, width: cellStyles.width, color: 'white' }}>Expiry</TableCell>
                    </>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {trainingSessions.map((row, index) => (
                  <TableRow key={index} style={{ cursor: 'pointer' }} onClick={event => window.location.href = `/training-session-detail/${row.id}`}>
                    {isMobile ? (
                      <TableCell sx={{ fontSize: cellStyles.fontSize }}>
                        {row.title}<br />
                        {format(parseISO(row.expires_at), 'eeee, MMMM do, yyyy')}
                      </TableCell>
                    ) : (
                      <>
                        <TableCell sx={{ fontSize: cellStyles.fontSize }}>{row.title}</TableCell>
                        <TableCell sx={{ fontSize: cellStyles.fontSize }}>{row.topics.length}</TableCell>
                        <TableCell sx={{ fontSize: cellStyles.fontSize }}>{row.no_questions}</TableCell>
                        <TableCell sx={{ fontSize: cellStyles.fontSize }}>{format(parseISO(row.expires_at), 'eeee, MMMM do, yyyy')}</TableCell>
                      </>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {trainingSessions.length === 0 &&
            <h5 className='noSubMsg' style={{ marginLeft: '15px' }}>You have no active training sessions</h5>
          }
          {shouldShow && <Alert
            severity="success"
            onClose={() => { setShouldShow(false) }}
            style={{ marginTop: 40, fontSize: '1.0em' }}
          >
            Thank you for subscribing. Your course is valid for 30 days. You can now start your training sessions.
          </Alert>}
          {shouldShowFailed && <Alert
            severity="error"
            onClose={() => { setShouldShowFailed(false) }}
            style={{ marginTop: 40, fontSize: '1.0em' }}
          >
            Subscribe not successful. Please try again.
          </Alert>}
        </div>

      </main>
    </div>
  );
}

export default HomeComponent;
