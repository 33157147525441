// import React from 'react';
// import {AppBar,Toolbar,IconButton,Typography,Menu,MenuItem,Box,ListItemText} from '@mui/material';
// import {
//   AccountCircle as AccountIcon,
//   ExitToApp as SignOutIcon,
// } from '@mui/icons-material';
// import logo from '../logo.webp';
// import { auth } from '../firebase';
// import { signOut } from "firebase/auth";

// const HeaderComponent = ({ handleAccountClick, handleMenuClose, anchorEl }) => {

//   // write function to handle firebase signout
//   const handleSignOut = async () => { 
//     await signOut(auth);
//     window.location.href = '/';
//   };

//   return (
//     <>
//       <Box sx={{ display: 'flex', flexDirection: 'column' }}>
//       <AppBar position="static">
      
//       <Toolbar >
//             {/* Logo */}
//             <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
//               <img
//                 src={logo}
//                 alt="Logo"
//                 style={{ height: '64px'}}
//               />
//             </Typography>
//             {/* Account Dropdown */}
//             <IconButton onClick={handleAccountClick} color="inherit" sx={{ marginRight: 1 }}>
//               <AccountIcon />
//               <Typography variant="subtitle1" sx={{ marginLeft: 1, verticalAlign: 'middle' }}>Account</Typography>
//             </IconButton>
//             <Menu
//               anchorEl={anchorEl}
//               open={Boolean(anchorEl)}
//               onClose={handleMenuClose}
//             >
//               <MenuItem onClick={handleMenuClose}>
//                 <ListItemText primary="Account Name" />
//               </MenuItem>
//               <MenuItem onClick={handleMenuClose}>
//                 <SignOutIcon />
//                 <ListItemText primary="Sign Out" onClick={handleSignOut} />
//               </MenuItem>
//             </Menu>
//           </Toolbar>
//     </AppBar>
//     </Box>
//     </>
//   );
// };

// export default HeaderComponent;


import React, { useState } from 'react';
import { AppBar, Toolbar, IconButton, Typography, Menu, MenuItem, Box, ListItemText } from '@mui/material';
import { AccountCircle as AccountIcon, ExitToApp as SignOutIcon } from '@mui/icons-material';
import logo from '../logo.webp';
import { auth } from '../firebase';
import { signOut } from "firebase/auth";
import CircularIndeterminate from '../components/Loader';

const HeaderComponent = ({ handleAccountClick, handleMenuClose, anchorEl }) => {
  const [loading, setLoading] = useState(false);

  // Write function to handle firebase signout
  const handleSignOut = async () => {
    setLoading(true);
    setTimeout(async () => {
      await signOut(auth);
      setTimeout(() => {
        window.location.href = '/';
      }, 1000); // Delay before redirecting
    }, 100); // Delay before signing out to show the loader
  };

  return (
    <>
      {loading && <CircularIndeterminate className="loader-container" size={24} />}
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <AppBar position="static">
          <Toolbar>
            {/* Logo */}
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              <img
                src={logo}
                alt="Logo"
                style={{ height: '64px' }}
              />
            </Typography>
            {/* Account Dropdown */}
            <IconButton onClick={handleAccountClick} color="inherit" sx={{ marginRight: 1 }}>
              <AccountIcon />
              <Typography variant="subtitle1" sx={{ marginLeft: 1, verticalAlign: 'middle' }}>Account</Typography>
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={handleMenuClose}>
                <ListItemText primary="Account Name" />
              </MenuItem>
              <MenuItem onClick={handleMenuClose}>
                <SignOutIcon />
                <ListItemText primary="Sign Out" onClick={handleSignOut} />
              </MenuItem>
            </Menu>
          </Toolbar>
        </AppBar>
      </Box>
    </>
  );
};

export default HeaderComponent;



