import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import '../App.css';
import { collection, addDoc, getDocs, query, where } from "firebase/firestore";
import { db, app, auth } from '../firebase';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import HeaderComponent from '../components/Header';
import FooterComponent from '../components/Footer';
import SideNavbarComponent from '../components/SideNavbar';
import { Box, Container, Paper } from '@mui/material';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

const dummy = [
  { value: 0, label: 'Answered' },
  { value: 100, label: 'Not answered' },
  { value: 15, label: 'Incorrectly answered' },
];

const size = {
  width: 400,
  height: 200,
};

export const TrainingSessionDetail = () => {
  const [currentUser, setCurrentUser] = useState(null);
  const [trainingSession, setTrainingSession] = useState(null);
  const [data, setData] = useState(null);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleAccountClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const [selectedOption, setSelectedOption] = useState('');

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const { tid } = useParams();

  const loadTrainingSession = async () => {
    console.log("Loading training session");
    const trainingSessions = JSON.parse(localStorage.getItem('training_sessions'));
    // const q = query(collection(db, "training_sessions"), where("user_uid", "==", user_uid));
    const q = query(collection(db, "training_sessions"));

    await getDocs(q)
      .then((querySnapshot) => {
        const trainingSessions = querySnapshot.docs
          .map((doc) => ({ ...doc.data(), id: doc.id }));
          const ts = trainingSessions.find(ts => ts.id === tid);
          setTrainingSession(ts);
          setData([
            { value: ts.topic_wise_stats.total.correct, label: 'Correct Answered' },
            { value: ts.topic_wise_stats.total.total - ts.topic_wise_stats.total.correct - ts.topic_wise_stats.total.incorrect, label: 'Not answered' },
            { value: ts.topic_wise_stats.total.incorrect, label: 'Incorrectly answered' },
          ])
        localStorage.setItem('training_sessions', JSON.stringify(trainingSessions));
      })

    
  }

  useEffect(() => {
    loadTrainingSession();
  }, [])


  return (
    <>

      {/* Header */}
      <HeaderComponent
        handleAccountClick={handleAccountClick}
        handleMenuClose={handleMenuClose}
        anchorEl={anchorEl}
      />

      {/* SideNavbar */}
      <SideNavbarComponent />

      {/* Content */}
      <Container sx={{ flexGrow: 1, display: 'flex' }}>
        <Box sx={{ flex: 1 }} style={{ marginLeft: '20px' }}>
          <Paper sx={{ p: 2, height: '87vh', borderRadius: 0, paddingTop: '30px !important' }}>
            <div className="App">
              <main className="MainContent">
                <div className="CardList">

                  <h3 style={{ marginBottom: 50, textDecoration: 'underline' }}>Training Session</h3>
                  <h4>{trainingSession ? trainingSession.title : ""}</h4>
                  <h5>No. of topics: {trainingSession ? trainingSession.topics.length : ""}</h5>
                  <h5 style={{ marginBottom: 75 }}>No. of questions: {trainingSession ? trainingSession.no_questions : ""}</h5>
                  <PieChart
                    colors={['#2CC388', '#0096FF', '#E43425']}
                    series={[
                      {
                        arcLabelMinAngle: 45,
                        data: data ? data : dummy,
                      },
                    ]}
                    sx={{
                      [`& .${pieArcLabelClasses.root}`]: {
                        fill: 'white',
                        fontWeight: 'bold',
                      },
                    }}
                    {...size}
                  />
                  <button style={{ marginTop: 75 }} onClick={event => window.location.href = `/training-session/${tid}`}>Open Session</button>
                </div>
              </main>
            </div>
          </Paper>
        </Box>
      </Container>

      {/* Footer */}
      <FooterComponent />

    </>

  );
}

export default TrainingSessionDetail;
