import React from 'react';
import {
  Container,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
} from '@mui/material';


export const TopicsWiseStats = () => {
  const getRandomColor = () => {
    const colors = ['lightgreen', 'lightyellow', 'lightorange', 'lightcoral'];
    const randomIndex = Math.floor(Math.random() * colors.length);
    return colors[randomIndex];
  };

  const getDummyPercentage = () => {
    return '0%';
    return `${Math.floor(Math.random() * 100)}%`;
  };

  const columnHeadings = ['Topic', 'Not answered yet', 'Never answered right', 'Answered right once', 'Answered right more than once', ''];

  const rowHeadings = [
    '0653 Special Operational Procedures and Hazards',
    '0654 Transport of Dangerous Goods',
    '0652 OPS Regulations',
    '0650 General Requirements',
    '0649 Accident and Investigation',
    '0648 Security',
  ];

  const tableData = rowHeadings.map((rowHeading) => [
    rowHeading,
    getDummyPercentage(),
    getDummyPercentage(),
    getDummyPercentage(),
    getDummyPercentage(),
    'Start',
  ]);

  return (
    <>
      <Container sx={{ minHeight: '70vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <TableContainer component={Paper} sx={{ width: 'calc(100% - 90px)', textAlign: 'center', overflowX: 'auto' }}>
          <Table sx={{ border: 'none' }}>
            <TableHead>
              <TableRow>
                {columnHeadings.map((heading, index) => (
                  <TableCell key={index} sx={{ fontSize: '10px', color: 'grey', padding: 'px', width: '10%', textAlign: 'center' }}>
                    {heading}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {tableData.map((row, rowIndex) => (
                <TableRow key={rowIndex} sx={{ '&:last-child td': { borderBottom: 'none' }, marginBottom: '15px', height: '10vh' }}>
                  {row.map((cell, cellIndex) => (
                    <TableCell key={cellIndex} sx={{ fontSize: '10px', padding: '8px', width: '10%', backgroundColor: cellIndex !== 0 && cellIndex !== row.length - 1 ? getRandomColor() : 'transparent', textAlign: 'center' }}>
                      {cellIndex === row.length - 1 ? (
                        <Button variant="outlined" sx={{ borderColor: 'green', color: 'green', fontSize: '9px', border: '3px solid green' }} onClick={event =>  window.location.href='/select-topics-page'}>
                          {cell}
                        </Button>
                      ) : cell}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </>
  );
};

export default TopicsWiseStats;
