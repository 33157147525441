import React, { useState } from 'react';
import { Box, Typography, Checkbox, Slider, Button, CircularProgress, Container, Grid, FormControlLabel } from '@mui/material';
import { collection, addDoc, getDocs } from "firebase/firestore";
import { db, auth } from '../firebase';
import CircularIndeterminate from '../components/Loader';
import '../App.css';

export const SelectTopics = () => {
  const [checkedItems, setCheckedItems] = useState([]);
  const [sliderValue, setSliderValue] = useState(0); // Initial value for the slider
  const [searchTerm, setSearchTerm] = useState('');
  const [currentUser, setCurrentUser] = React.useState(null);
  const [selectAll, setSelectAll] = useState(false);
  const [loading, setLoading] = React.useState(false); // Loading state

  React.useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setCurrentUser(user);
    });

    return () => unsubscribe();
  }, []);

  const createTrainingSession = async () => {
    setLoading(true); // Show loader

    try {
      const docRef = await addDoc(collection(db, "training_sessions"), {
        user_uid: currentUser.uid,
        course_uid: "Japan",
        topics: checkedItems,
        no_questions: sliderValue,
        title: 'AIR LAW AND OPERATIONAL PROCEDURES GCAA',
        created_at: new Date().toISOString(),
        expires_at: new Date(Date.now() + 60 * 24 * 60 * 60 * 1000).toISOString(),
        stats: [
          { topic: 'Special Operational Procedures and Hazards', correct: 0, incorrect: 0, total: 10 },
          { topic: 'Transport of Dangerous Goods', correct: 0, incorrect: 0, total: 10 },
        ]
      });

      const questionsSnapshot = await getDocs(collection(db, "questions"));
      const questionsData = questionsSnapshot.docs.slice(0, sliderValue);

      for (let i = 0; i < questionsData.length; i++) {
        const doc = questionsData[i].data();
        await addDoc(collection(db, "training_sessions", docRef.id, "questions"), {...doc, chosen_answer: null});
      }

      // Simulate a delay before navigation (remove in production)
      await new Promise(resolve => setTimeout(resolve, 1000));

      // Navigate to the next page using React Router's history object
      window.location.href = `/training-session/${docRef.id}`;
    } catch (error) {
      console.error('Error creating training session:', error);
    } finally {
      setLoading(false); // Hide loader
    }
  };

  const handleCheckboxChange = (topic) => {
    const newCheckedItems = checkedItems.includes(topic)
      ? checkedItems.filter((item) => item !== topic)
      : [...checkedItems, topic];

    setCheckedItems(newCheckedItems);
  };

  const handleSliderChange = (event, newValue) => {
    setSliderValue(newValue);
  };

  const handleSelectAllChange = () => {
    if (selectAll) {
      setCheckedItems([]);
    } else {
      setCheckedItems(topics);
    }
    setSelectAll(!selectAll);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // Array of topics
  const topics = [
    'Special Operational Procedures and Hazards',
    'Transport of Dangerous Goods',
    'Long Range Flights',
    'OPS Regulations',
    'General Requirements',
    'Accident and Investigation',
    'Security',
    'SAR (Search and Rescue)',
    'Aerodromes',
    'AIS & Publications',
    'ATC & Air Traffic Management',
    'Procedures for Air Navigation Services',
    'OPS Regulation: GCAA specific',
    'Conventions and Agreements',
    'Air Navigation',
    'Airworthiness',
    'Personell Licensing',
    'Rules of the Air',
  ];

  // Filter topics based on search term
  const filteredTopics = topics.filter((topic) =>
    topic.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const breadcrumbs = ['Home', 'Air Law and Operational Procedures GCAA', 'Select Topics'];

  return (
    <Container>
      <Box display="flex" alignItems="center" justifyContent="space-between" mb={4} style={{ marginLeft: '30px' }}>
        <div className="Breadcrumbs">
          {breadcrumbs.map((breadcrumb, index) => (
            <React.Fragment key={index}>
              <span className={index === breadcrumbs.length - 1 ? 'ActiveBreadcrumb' : 'Breadcrumb'}>
                {breadcrumb}
              </span>
              {index !== breadcrumbs.length - 1 && <span className="BreadcrumbSeparator">/</span>}
            </React.Fragment>
          ))}
        </div>
      </Box>
      <Box mb={0} style={{ marginLeft: '30px' }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={selectAll}
              onChange={handleSelectAllChange}
            />
          }
          label={
            <Typography variant="body1" fontSize={13}>
              Select All Topics
            </Typography>
          }
        />
      </Box>
      <div style={{ maxHeight: '250px', overflowY: 'auto', maxWidth: '300px', marginLeft: '30px' }}>
        <Grid container spacing={0}>
          {filteredTopics.map((topic, index) => (
            <Grid key={index} item xs={12} sx={{ marginBottom: '-13px' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkedItems.includes(topic)}
                    onChange={() => handleCheckboxChange(topic)}
                  />
                }
                label={topic}
              />
            </Grid>
          ))}
        </Grid>
      </div>
      <Box mt={8} mb={4} style={{ marginLeft: '30px' }}>
        <Typography variant="h5" gutterBottom>
          Select number of questions
        </Typography>
        <Slider
          value={sliderValue}
          onChange={handleSliderChange}
          valueLabelDisplay="auto"
          step={1}
          marks
          min={1}
          max={150}
          sx={{ maxWidth: 300 }}
        />
        <Typography variant="body2">
          No. of Questions: {sliderValue}
        </Typography>
      </Box>
      <Box mt={4} mb={2}>
        <Button
          variant="contained"
          color="primary"
          onClick={createTrainingSession}
          disabled={loading} // Disable button when loading
        >
          {loading ? <CircularIndeterminate size={24} /> : 'Setup Training Session'}
        </Button>
      </Box>
    </Container>
  );
};

export default SelectTopics;
