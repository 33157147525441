import React from 'react';
import '../App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faPlane, faCartShopping, faContactCard, faTrash, faPaperPlane } from '@fortawesome/free-solid-svg-icons';

const cardData = [
  {
    id: 1,
    title: 'AIR LAW AND OPERATIONAL PROCEDURES',
    imageUrl: 'https://via.placeholder.com/150',
  },
  {
    id: 2,
    title: 'AIR AND DEMO OPERATIONAL PROCEDURES',
    imageUrl: 'https://via.placeholder.com/150',
  },
  {
    id: 3,
    title: 'AIR AND GGCA OPERATIONAL PROCEDURES',
    imageUrl: 'https://via.placeholder.com/150',
  },
];

const tableData = [
    {
      catalog: 'Air Law and Operational Procedures GCAA',
      subjects: '1',
      questions: 8109,
      action: 'Select',
    },
    {
      catalog: 'CAAC Chinese ATPL',
      subjects: '1',
      questions: 1155,
      action: 'Select',
    },
  ];

const breadcrumbs = ['Home', 'Catalog Store'];
  

function CatalogComponent() {
  return (
    <div className="App">
      <header className="Header">
        <div className="Logo">Aviation <FontAwesomeIcon icon={faPlane} /></div>
        <nav className="Menu">
          <ul>
            <li>satishreddy1351@gmail.com</li>
            {/* <li>Menu Item 2</li>
            <li>Menu Item 3</li> */}
          </ul>
        </nav>
      </header>
      <main className="MainContent">
        <div className="Sidebar">
          <ul>
           <li onClick={event =>  window.location.href='/'}>
              <FontAwesomeIcon icon={faHome} />
              <span>Home</span>
            </li>
            <li onClick={event =>  window.location.href='/catalog'}>
              <FontAwesomeIcon icon={faCartShopping} />
              <span>Catalog</span>
            </li>
            <li onClick={event =>  window.location.href='/training-sessions-list'}>
              <FontAwesomeIcon icon={faPaperPlane} />
              <span>Traning Sessions</span>
            </li>
            <li>
              <FontAwesomeIcon icon={faContactCard} />
              <span>Support</span>
            </li>
          </ul>
        </div>
        <div className="CardList">
        <div className="Breadcrumbs">
          {breadcrumbs.map((breadcrumb, index) => (
            <React.Fragment key={index}>
              <span className={index === breadcrumbs.length - 1 ? 'ActiveBreadcrumb' : 'Breadcrumb'}>
                {breadcrumb}
              </span>
              {index !== breadcrumbs.length - 1 && <span className="BreadcrumbSeparator">/</span>}
            </React.Fragment>
          ))}
        </div>
        <div className="TableContainer">
          <table className="Table">
            <thead>
              <tr>
                <th>Catalog</th>
                <th>Subjects</th>
                <th>Questions</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((row, index) => (
                <tr key={index}>
                  <td>{row.catalog}</td>
                  <td>{row.subjects}</td>
                  <td>{row.questions}</td>
                  <td><button onClick={event =>  window.location.href='/catalog-item'}>{row.action}</button></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        </div>
      </main>
    </div>
  );
}

export default CatalogComponent;
