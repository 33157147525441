import React from 'react';
import { AppBar, Toolbar, Typography , Box } from '@mui/material';

const FooterComponent = () => {
  return (
    <>
      <Box component="footer" sx={{ p: 2, mt: 'auto', backgroundColor: '#f5f5f5' }}>
          <Typography variant="body2" color="textSecondary" align="center">
            Contact us | FAQs | Copyright
          </Typography>
      </Box>
    </> 
  );
};

export default FooterComponent;