import React, { useState, useEffect } from "react"; 
import '../App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { loadStripe } from "@stripe/stripe-js"; 
import { faHome, faPlane, faCartShopping, faContactCard, faTrash, faPaperPlane, faList } from '@fortawesome/free-solid-svg-icons';
import Alert from '@mui/material/Alert';


const cardData = [
    {
      id: 1,
      title: 'Air Law and Operational Procedures GCAA',
      description: 'The subject Air Law and Operational Procedures GCAA contains 1155 questions and 18 Subcategories. It is part of the BUNDLE: Air Law and Operational Procedures GCAA + B777 Type Rating package.',
      link: 'Take a look at some sample questions',
      text: '1155 Questions',
    },
    {
      id: 2,
      title: 'B777 Type Rating',
      description: 'The subject B777 Type Rating contains 825 questions and 16 Subcategories. It is part of the BUNDLE: Air Law and Operational Procedures GCAA + B777 Type Rating package.',
      link: 'Take a look at some sample questions',
      text: '825 Questions',
    },
  ];

const tableData = [
    {
      catalog: 'Air Law and Operational Procedures GCAA',
      subjects: '1',
      questions: 8109,
      action: 'Select',
    },
    {
      catalog: 'CAAC Chinese ATPL',
      subjects: '1',
      questions: 1155,
      action: 'Select',
    },
  ];

const breadcrumbs = ['Home', 'Catalog Store', 'Air Law and Operational Procedures GCAA'];
  

export const CatalogItemComponent = () => {

    const [items, setItems] = useState([]);

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem('items'));
    if (items) {
     setItems(items);
    }
  }, []);


    const [product, setProduct] = useState({ 
        name: "Air Law and Operational Procedures GCAA", 
        price: 1000, 
        productOwner: "Aviation Site", 
        description: 
          "The subject Air Law and Operational Procedures GCAA contains 1155 questions and 18 Subcategories. It is part of the BUNDLE: Air Law and Operational Procedures GCAA + B777 Type Rating package.", 
        quantity: 1, 
      }); 

    const makePayment = async () => { 
        const stripe = await loadStripe("pk_test_51NVzOHSEccTJVT5G27q5UDJ7BLFW5HHqUqnKalMTMwqWaObXrSOvugGy42IQlT97LEVAwDPOlRoF1dPtOAs33vU500CiMdEqJ4"); 
        const body = { product }; 
        const headers = { 
          "Content-Type": "application/json", 
        }; 
     
        const response = await fetch( 
          "http://localhost:8000/api/create-checkout-session", 
          { 
            method: "POST", 
            headers: headers, 
            body: JSON.stringify(body), 
          } 
        ); 
     
        const session = await response.json(); 
        let items = [
            {
              id: 1,
              title: 'AIR LAW AND OPERATIONAL PROCEDURES GCAA',
              imageUrl: 'https://via.placeholder.com/150',
            },
          ]
        localStorage.setItem('items', JSON.stringify(items));
     
        const result = stripe.redirectToCheckout({ 
          sessionId: session.id, 
        }); 
     
        if (result.error) { 
          console.log(result.error); 
        } 
      };  

  return (
    <div className="App">
      <main className="MainContent">
        <div className="CardList">
        <div className="Breadcrumbs">
          {breadcrumbs.map((breadcrumb, index) => (
            <React.Fragment key={index}>
              <span className={index === breadcrumbs.length - 1 ? 'ActiveBreadcrumb' : 'Breadcrumb'}>
                {breadcrumb}
              </span>
              {index !== breadcrumbs.length - 1 && <span className="BreadcrumbSeparator">/</span>}
            </React.Fragment>
          ))}
        </div>
        <div className="Column Column1">
          {cardData.map((card) => (
            <div className="SubCard" key={card.id}>
              <div className="CardTitle">{card.title}</div>
              <hr/>
              <div className="CardDescription">{card.description}</div>
              <div className="CardFooter">
                <div className="CardLink" onClick={event =>  window.location.href='/sample-questions'}>{card.link}</div> 
                {/* <div className="CardLink" onClick={handleLinkClick}>{card.link}</div>  */}
                <div className="CardText"><FontAwesomeIcon icon={faList} />&nbsp; {card.text}</div>
              </div>
            </div>
          ))}
        </div>
        <div className="Column Column2">
            { (items.length > 0 && items[0].id == 1) && <button className='subscribeOn' onClick={event =>  window.location.href='/topics-wise-stats-page'}>Start Training</button> }
            { items.length == 0 && <button className='subscribe' onClick={makePayment}>Subscribe</button> }
        </div>
        </div>
      </main>
      <Alert severity="warning" onClose={() => {}}>
        This Alert displays the default close icon.
      </Alert>
    </div>
  );
}

export default CatalogItemComponent;
