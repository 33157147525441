import React from "react"; 
import { BrowserRouter, Routes, Route } from "react-router-dom"; 

import StripePayment from "./pages/StripePayment"; 
import SuccessPage from "./pages/SuccessPage"; 
import CancelPage from "./pages/CancelPage"; 
import HeaderComponent from "./components/Header"; 
import FooterComponent from "./components/Footer"; 
import HomePage from "./pages/HomePage";  
import Login from "./pages/LoginFB"; 
import CatalogPage from "./pages/CatalogPage"; 
import CatalogComponent from "./pages/NewCatalog"; 
import CatalogItemPage from "./pages/CatalogItemPage"; 
import TestComponent from "./NotNeeded/Test"; 
import TrainingSessionComponent from "./pages/TrainingSession"; 
import TrainingSession2Component from "./NotNeeded/TrainingSession2"; 
import TrainingSessionsListComponent from "./pages/TrainingSessionsList"; 
import SelectTopicsPage from "./pages/SelectTopicsPage";
import TopicsWiseStatsPage from "./pages/TopicsWiseStatsPage";
import StripePaymentPage from "./pages/StripePaymentPage";
import TrainingSessionPage from "./pages/TrainingSessionPage";
import TrainingSessionDetail from "./pages/TrainingSessionDetail";
import TrainingSessionsListPage from "./pages/TrainingSessionsListPage";
import SampleQuestions from "./pages/SampleQuestions";
import ActiveSessionsPage from "./pages/ActiveSessionsPage";
import ActiveSubscriptionPage from "./pages/ActiveSubscriptionPage";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
// import { auth } from './firebase-config'; // Import your auth setup
import { db, app, auth } from './firebase';

function ProtectedRoute({ children }) {
  const [user, loading, error] = useAuthState(auth);
  const location = useLocation();

  if (loading) {
    return <div>Loading...</div>; // Or any other loading state representation
  }

  if (!user) {
    // Redirect them to the /login page, but save the current location they were trying to go to
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

let theme = createTheme({
  palette: {
    primary: {
      main: '#4A0404',
    },
    secondary: {
      main: '#edf2ff',
    },
  },
});

theme = createTheme(theme, {
  palette: {
    info: {
      main: theme.palette.secondary.main,
    },
  },
});


// import DashboardComponent from "./components/Dashboard"; 
 
function App() { 
  return ( 
    <ThemeProvider theme={theme}>
    <BrowserRouter> 
      <Routes> 
        <Route path="/success-page" element={<SuccessPage />} /> 
        <Route path="/cancel-page" element={<CancelPage />} /> 
        <Route path="/" element={<ProtectedRoute><HomePage /> </ProtectedRoute>}/>
        <Route path="/login" element={<Login />} />
        <Route path="/catalog" element={<CatalogPage />} />
        {/* <Route path="/catalog" element={<CatalogComponent />} /> */}
        <Route path="/catalog-item-page" element={<CatalogItemPage />} />
        <Route path="/training-session/:tid" element={<TrainingSessionPage />} />
        {/* <Route path="/training-session-2" element={<TrainingSession2Component />} /> */}
        {/* <Route path="/test" element={<TestComponent />} /> */}
        <Route path="/success-page" element={<SuccessPage />} />
        <Route path="/subscribe" element={<StripePayment />} /> 
        <Route path="/subscriptions" element={<ActiveSubscriptionPage />} />
        <Route path="/training-sessions" element={<ActiveSessionsPage />} /> 
        <Route path="/training-sessions-list" element={<TrainingSessionsListPage />} /> 
        <Route path="/training-session-detail/:tid" element={<TrainingSessionDetail />} />
        <Route path="/select-topics-page" element={<SelectTopicsPage />} /> 
        <Route path="/topics-wise-stats-page" element={<TopicsWiseStatsPage />} />
        <Route path="/stripe-payment-page" element={<StripePaymentPage />} />
        <Route path="/sample-questions" element={<SampleQuestions />} />
      </Routes> 
    </BrowserRouter> 
    </ThemeProvider>
  ); 
}  
export default App; 
