import React, { useState } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import '../App.css';
import { Grid, Paper, TextField, Button, Typography, Box } from '@mui/material';
import logo from '../logo.webp';
import bg from '../bg1.jpeg';

const FlashSaleBanner = ({ onClose }) => {
  return (
      <div style={{
          position: 'fixed',
          bottom: '80px',
          right: '20px',
          // backgroundColor: '#995D62', // Orangered color; adjust as needed
          background: 'linear-gradient(45deg, #ff5f6d, #ffc371)', // Gradient from pink to orange
          // background: 'linear-gradient(45deg, #995D62, #DCAE96)',
          color: 'white',
          padding: '10px',
          paddingTop: '30px', // Extra padding at the top for the close button
          textAlign: 'center',
          borderRadius: '10px', // Gives rounded corners
          boxShadow: '0 4px 8px rgba(0,0,0,0.5)', // Optional shadow for better visibility
          zIndex: 1000 // Ensure it's above other content
      }}>
          <button onClick={onClose} style={{
              position: 'absolute',
              top: '0px',
              right: '10px',
              fontSize: '16px',
              padding: '5px',
              backgroundColor: 'transparent',
              color: 'white',
              border: 'none',
              cursor: 'pointer',
              outline: 'none'
          }}>
              &#x2715; {/* Unicode character for a multiplication sign (cross) */}
          </button>
          <h2 style={{ margin: '0 0 10px 0' }}>
  SALE at <span style={{ textDecoration: 'line-through' }}>$49.99</span> $19.99!
</h2>
          <p style={{ margin: '0 0 10px 0' }}>Course subscription valid for 30 days!</p>
      </div>
  );
};



// Replace with your actual Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCygpaEDfKffB5bPvJ4rAKrv-QPdRPB4pc",
  authDomain: "avia-cc8a6.firebaseapp.com",
  projectId: "avia-cc8a6",
  storageBucket: "avia-cc8a6.appspot.com",
  messagingSenderId: "606018202444",
  appId: "1:606018202444:web:8d1a9b3aef5314e8efbde9"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

function Login() {
  const [user, setUser] = useState(null);
  const [showBanner, setShowBanner] = useState(true);

  const signInWithGoogle = async () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    try {
      const result = await firebase.auth().signInWithPopup(provider);
      setUser(result.user);
      window.location.href = "/";
    } catch (error) {
      console.error('Google Sign-In Error:', error);
    }
  };

  const signOut = async () => {
    try {
      await firebase.auth().signOut();
      setUser(null);
    } catch (error) {
      console.error('Sign Out Error:', error);
    }
  };

  const handleClose = () => {
    setShowBanner(false);
};

  return (
    <Grid container style={{ minHeight: '100vh' }}>
      
      <Grid item xs={12} sm={6}>
                <Box 
                    sx={{
                        width: '100%', 
                        height: '100%', 
                        backgroundImage: `url(${bg})`,
                        backgroundSize: 'cover',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'relative'
                    }}
                >
                    <Box 
                        sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            backgroundColor: 'rgba(0,0,0,0.55)'
                        }}
                    />
                    <Box 
                        sx={{
                            zIndex: 1,
                            color: 'white',
                            textAlign: 'center'
                        }}
                    >
                        <Typography variant="h2" component="h1" style={{opacity: 0.7, fontWeight: 800}}>
                        Welcome to UAE Airlaw Study Guide <br/>Unlock your potential in the skies.
                          
                        </Typography>
                        <Typography variant="h5" component="h2" style={{marginTop: 20, opacity: 0.6, paddingLeft: 60, paddingRight: 60, fontSize: 18}}>
                        Prepare for your pilot exams with ease. Log in now and navigate your path to becoming a pilot with our expertly designed preparation materials. Start your journey to the cockpit today!
                          <br/><br/><br/><p style={{opacity: 0.9, fontWeight: 300}}>Course subscription valid for 30 days</p>
                        </Typography>
                    </Box>
                </Box>
            </Grid>
      <Grid container item xs={12} sm={6} alignItems="center" direction="column" justify="space-between" style={{ padding: 10 }}>
        
        <div style={{ display: 'flex', flexDirection: 'column', maxWidth: 400, minWidth: 300 }}>
          <Grid container justify="center" style={{marginTop: 120}}>
            <img
              src={logo} // Replace with your logo
              width={400}
              alt="logo"
            />
          </Grid>
          <div className="LoginContainer">
            <div className="LoginBox">
              <h2>Welcome</h2>
              <p>Please sign in to continue</p>
              <button className="GoogleButton" onClick={signInWithGoogle}>
                <img
                  src="https://developers.google.com/identity/images/g-logo.png"
                  alt="Google Logo"
                />
                Sign in with Google
              </button>
            </div>
          </div>
        </div>
        <Box component="footer" sx={{ p: 2, mt: 'auto' }}>
          <Typography variant="body1" color="textSecondary" align="center">
            Contact us | FAQs | Copyright
          </Typography>
      </Box>
      {showBanner && <FlashSaleBanner onClose={handleClose} />}
      </Grid>
     
    </Grid>

  );
}

export default Login;
