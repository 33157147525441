// import React from 'react';
// import { useParams } from 'react-router-dom';
// import HeaderComponent from '../components/Header';
// import FooterComponent from '../components/Footer';
// import SideNavbarComponent from '../components/SideNavbar';
// import CircularIndeterminate from '../components/Loader';
// import { Box, Container, Paper } from '@mui/material';
// import { useState, useEffect } from "react";
// import '../App.css';
// import { collection, addDoc, getDocs, doc, setDoc } from "firebase/firestore";
// import { db, app, auth } from '../firebase';
// import Pagination from '@mui/material/Pagination';
// import { loadStripe } from "@stripe/stripe-js";

// const cardData = [
//   {
//     id: 1,
//     title: 'Question 1',
//     description: 'The application of a type II anti-icing fuild on an aeroplane on the ground will provide a:',
//     link: 'Attachments',
//     text: '1155 Questions',
//     options: [
//       'Limited time of protection',
//       'Protection upto 24 hours',
//       'Protection against icing for duration of the flight',
//       'Limited protection independent of the outside temperature'
//     ]
//   },
//   {
//     id: 2,
//     title: 'Question 2',
//     description: 'During a de-icing/anti-icing procedure carried out in two steps, the holdover time starts',
//     link: 'Attachments',
//     text: '825 Questions',
//     options: [
//       'At the beginning of the first step',
//       'At the end of the first step',
//       'At the beginning of the second step',
//       'At the end of the second step'
//     ]
//   },
// ];

// const tableData = [
//   {
//     catalog: 'Air Law and Operational Procedures GCAA',
//     subjects: '1',
//     questions: 8109,
//     action: 'Select',
//   },
//   {
//     catalog: 'CAAC Chinese ATPL',
//     subjects: '1',
//     questions: 1155,
//     action: 'Select',
//   },
// ];

// const breadcrumbs = ['Home', 'Courses', 'UAE Air Law and Operational Procedures GCAA'];


// export const TrainingSessionPage = () => {
//   const [questions, setQuestions] = useState([]);
//   const { tid } = useParams();

//   const [product, setProduct] = useState({ 
//     name: "UAE Air Law and Operational Procedures GCAA", 
//     price: 1000, 
//     productOwner: "Aviation Site", 
//     description: 
//       "The subject Air Law and Operational Procedures GCAA contains 1155 questions and 18 Subcategories. It is part of the BUNDLE: Air Law and Operational Procedures GCAA + B777 Type Rating package.", 
//     quantity: 1, 
//   }); 

//   const fetchQuestions = async () => {
//     console.log("fetching questions");

//     await getDocs(collection(db, "training_sessions", tid, "questions"))
//       .then((querySnapshot) => {
//         const newData = querySnapshot.docs
//           .map((doc) => ({ ...doc.data(), id: doc.id }));
//         console.log(newData);
//         setQuestions(newData);
//         console.log(questions, newData);
//       })

//   }

//   const handleOptionChange = async (event, questionData) => {
//     questions[cardIdx].chosen_answer = event.target.value;
//     setSelectedOption(event.target.value);
//     const qRef = doc(db, 'training_sessions', tid, 'questions', questionData.id);
//     await setDoc(qRef, { chosen_answer: parseInt(event.target.value) }, { merge: true });
//   };

//   useEffect(() => {
//     const items = JSON.parse(localStorage.getItem('items'));
//     if (items) {
//       setItems(items);
//     }
//     fetchQuestions();
//   }, [])


//   useEffect(() => {
//     const unsubscribe = auth.onAuthStateChanged(user => {
//       setCurrentUser(user);
//     });

//     return () => unsubscribe();
//   }, []);

//   const [anchorEl, setAnchorEl] = React.useState(null);
//   const [cardIdx, setCardIdx] = useState(0);
//   const [currentUser, setCurrentUser] = React.useState(null);

//   const handleChange = (event, value) => {
//     setCardIdx(value - 1);
//   };

//   const handleSubmit = async () => {
//     let topicsMap = {};
//     topicsMap["total"] = { correct: 0, incorrect: 0, total: 0, unanswered: 0 };
//     for (let i = 0; i < questions.length; i++) {
//       console.log(questions[i]);
//       if (topicsMap[questions[i].topic]) {
//         topicsMap[questions[i].topic].total += 1;
//         if (questions[i].right_answer === questions[i].chosen_answer) {
//           topicsMap[questions[i].topic].correct += 1;
//         } else if (questions[i].chosen_answer === null) {
//           topicsMap[questions[i].topic].unanswered += 1;
//         }
//         else {
//           topicsMap[questions[i].topic].incorrect += 1;
//         }
//       } else {
//         topicsMap[questions[i].topic] = { correct: 0, incorrect: 0, total: 1, unanswered: 0 };
//         if (questions[i].right_answer === questions[i].chosen_answer) {
//           topicsMap[questions[i].topic].correct += 1;
//         } else if (questions[i].chosen_answer === null) {
//           topicsMap[questions[i].topic].unanswered += 1;
//         }
//         else {
//           topicsMap[questions[i].topic].incorrect += 1;
//         }
//       }
//       topicsMap["total"].total += 1;
//       if (questions[i].right_answer === questions[i].chosen_answer) {
//         topicsMap["total"].correct += 1;
//       } else if (questions[i].chosen_answer === null) {
//         topicsMap["total"].unanswered += 1;
//       }
//       else {
//         topicsMap["total"].incorrect += 1;
//       }
      
//       const qRef = doc(db, 'training_sessions', tid);
//       await setDoc(qRef, { topic_wise_stats: topicsMap }, { merge: true });
//       console.log(topicsMap);
//     }
//     window.location.href = `/training-session-detail/${tid}`;
//   };

//   const handleAccountClick = (event) => {
//     setAnchorEl(event.currentTarget);
//   };

//   const handleMenuClose = () => {
//     setAnchorEl(null);
//   };

//   const [selectedOption, setSelectedOption] = useState('');



//   const prevQn = async () => {
//     await setCardIdx(cardIdx - 1);
//   }

//   const nextQn = async () => {
//     await setCardIdx(cardIdx + 1);
//   }

//   const makePayment = async (course_uid) => {
//     const stripe = await loadStripe("pk_test_51NVzOHSEccTJVT5G27q5UDJ7BLFW5HHqUqnKalMTMwqWaObXrSOvugGy42IQlT97LEVAwDPOlRoF1dPtOAs33vU500CiMdEqJ4");
//     const body = { product };
//     const headers = {
//       "Content-Type": "application/json",
//     };

//     const response = await fetch(
//       "http://localhost:8000/api/create-checkout-session",
//       {
//         method: "POST",
//         headers: headers,
//         body: JSON.stringify(body),
//       }
//     );

//     const session = await response.json();
//     let items = [
//       {
//         id: 1,
//         title: 'UAE AIR LAW AND OPERATIONAL PROCEDURES GCAA',
//         imageUrl: 'https://via.placeholder.com/150',
//       },
//     ]
//     localStorage.setItem('items', JSON.stringify(items));

//     const docRef = await addDoc(collection(db, "subscriptions"), {
//       user_uid: currentUser.uid,
//       course_uid: course_uid,
//       subscribed_at: "2021-10-10",
//       is_active: true,
//       title: 'UAE AIR LAW AND OPERATIONAL PROCEDURES GCAA',
//       imageUrl: 'https://via.placeholder.com/150'
//     });

//     const result = stripe.redirectToCheckout({
//       sessionId: session.id,
//     });

//     if (result.error) {
//       console.log(result.error);
//     }
//   };




//   const [items, setItems] = useState([]);

//   return (
//     <>
//       {/* Header */}
//       <HeaderComponent
//         handleAccountClick={handleAccountClick}
//         handleMenuClose={handleMenuClose}
//         anchorEl={anchorEl}
//       />

//       {/* SideNavbar */}
//       <SideNavbarComponent />

//       {/* Content */}
//       <Container sx={{ flexGrow: 1, display: 'flex' }}>
//         <Box sx={{ flex: 1 }}>
//           <Paper sx={{ p: 2, height: '85vh', borderRadius: 0, paddingTop: '30px !important' }}>
//             <div className="CardList" style={{ marginLeft: '20px' }}>
//               <div className="Breadcrumbs" >
//                 {breadcrumbs.map((breadcrumb, index) => (
//                   <React.Fragment key={index}>
//                     <span className={index === breadcrumbs.length - 1 ? 'ActiveBreadcrumb' : 'Breadcrumb'}>
//                       {breadcrumb}
//                     </span>
//                     {index !== breadcrumbs.length - 1 && <span className="BreadcrumbSeparator">/</span>}
//                   </React.Fragment>
//                 ))}
//               </div>
//               <div className="Column Column1">
//                 <div className="SubCard" key={questions[cardIdx]?.id}>
//                   <div className="CardTitle">Question #{cardIdx + 1}</div>
//                   <hr />
//                   <div className="CardTitle"><p>{questions[cardIdx]?.text}</p></div>
//                   <div className="RadioButtons">
//                     <label className="RadioButton">
//                       <input
//                         type="radio"
//                         name="radAnswer"
//                         value={0}
//                         checked={questions[cardIdx]?.chosen_answer == 0}
//                         onChange={event => handleOptionChange(event, questions[cardIdx])}
//                       />
//                       {questions[cardIdx]?.op_a}
//                     </label>
//                     <label className="RadioButton">
//                       <input
//                         type="radio"
//                         name="radAnswer"
//                         value={1}
//                         checked={questions[cardIdx]?.chosen_answer == 1}
//                         onChange={event => handleOptionChange(event, questions[cardIdx])}
//                       />
//                       {questions[cardIdx]?.op_b}
//                     </label>
//                     <label className="RadioButton">
//                       <input
//                         type="radio"
//                         name="radAnswer"
//                         value={2}
//                         checked={questions[cardIdx]?.chosen_answer == 2}
//                         onChange={event => handleOptionChange(event, questions[cardIdx])}
//                       />
//                       {questions[cardIdx]?.op_c}
//                     </label>
//                     <label className="RadioButton">
//                       <input
//                         type="radio"
//                         name="radAnswer"
//                         value={3}
//                         checked={questions[cardIdx]?.chosen_answer == 3}
//                         onChange={event => handleOptionChange(event, questions[cardIdx])}
//                       />
//                       {questions[cardIdx]?.op_d}
//                     </label>
//                   </div>
//                   <div className="CardFooter">
//                     <div className="CardLink">{questions[cardIdx]?.link}

//                     </div>


//                   </div>
//                 </div>
//               </div>

//               <div className="Column Column2">
//                 <Pagination onChange={handleChange} style={{ marginTop: 50 }} count={questions.length} variant="outlined" color="primary" shape="rounded" showFirstButton showLastButton />
//                 { tid !== "4vgV1cTgXi5hm00Udk2k" && <button style={{ marginTop: 30 }} className='subscribe' onClick={handleSubmit}>Submit</button>}
//                 { tid === "4vgV1cTgXi5hm00Udk2k" && <button style={{ marginTop: 30 }} className='subscribe' onClick={() => makePayment(tid)}>Subscribe</button>}
//               </div>
//             </div>
//           </Paper>
//         </Box>
//       </Container>

//       {/* Footer */}
//       <FooterComponent />
//     </>
//   );
// };

// export default TrainingSessionPage;


import React from 'react';
import { useParams } from 'react-router-dom';
import HeaderComponent from '../components/Header';
import FooterComponent from '../components/Footer';
import SideNavbarComponent from '../components/SideNavbar';
import { Box, Container, Paper } from '@mui/material';
import { useState, useEffect } from "react";
import '../App.css';
import { collection, addDoc, getDocs, doc, setDoc } from "firebase/firestore";
import { db, app, auth } from '../firebase';
import Pagination from '@mui/material/Pagination';
import { loadStripe } from "@stripe/stripe-js";
import CircularIndeterminate from '../components/Loader';

const cardData = [
  {
    id: 1,
    title: 'Question 1',
    description: 'The application of a type II anti-icing fuild on an aeroplane on the ground will provide a:',
    link: 'Attachments',
    text: '1155 Questions',
    options: [
      'Limited time of protection',
      'Protection upto 24 hours',
      'Protection against icing for duration of the flight',
      'Limited protection independent of the outside temperature'
    ]
  },
  {
    id: 2,
    title: 'Question 2',
    description: 'During a de-icing/anti-icing procedure carried out in two steps, the holdover time starts',
    link: 'Attachments',
    text: '825 Questions',
    options: [
      'At the beginning of the first step',
      'At the end of the first step',
      'At the beginning of the second step',
      'At the end of the second step'
    ]
  },
];

const tableData = [
  {
    catalog: 'Air Law and Operational Procedures GCAA',
    subjects: '1',
    questions: 8109,
    action: 'Select',
  },
  {
    catalog: 'CAAC Chinese ATPL',
    subjects: '1',
    questions: 1155,
    action: 'Select',
  },
];

const breadcrumbs = ['Home', 'Courses', 'UAE Air Law and Operational Procedures GCAA'];

export const TrainingSessionPage = () => {
  const [questions, setQuestions] = useState([]);
  const { tid } = useParams();
  const [loading, setLoading] = useState(false); // Loading state
  const [product, setProduct] = useState({ 
    name: "UAE Air Law and Operational Procedures GCAA", 
    price: 1000, 
    productOwner: "Aviation Site", 
    description: 
      "The subject Air Law and Operational Procedures GCAA contains 1155 questions and 18 Subcategories. It is part of the BUNDLE: Air Law and Operational Procedures GCAA + B777 Type Rating package.", 
    quantity: 1, 
  }); 

  const fetchQuestions = async () => {
    console.log("fetching questions");

    await getDocs(collection(db, "training_sessions", tid, "questions"))
      .then((querySnapshot) => {
        const newData = querySnapshot.docs
          .map((doc) => ({ ...doc.data(), id: doc.id }));
        console.log(newData);
        setQuestions(newData);
        console.log(questions, newData);
      })
  }

  const handleOptionChange = async (event, questionData) => {
    questions[cardIdx].chosen_answer = event.target.value;
    setSelectedOption(event.target.value);
    const qRef = doc(db, 'training_sessions', tid, 'questions', questionData.id);
    await setDoc(qRef, { chosen_answer: parseInt(event.target.value) }, { merge: true });
  };

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem('items'));
    if (items) {
      setItems(items);
    }
    fetchQuestions();
  }, [])

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setCurrentUser(user);
    });

    return () => unsubscribe();
  }, []);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [cardIdx, setCardIdx] = useState(0);
  const [currentUser, setCurrentUser] = React.useState(null);

  const handleChange = (event, value) => {
    setCardIdx(value - 1);
  };

  const handleSubmit = async () => {
    setLoading(true); // Set loading to true
    let topicsMap = {};
    topicsMap["total"] = { correct: 0, incorrect: 0, total: 0, unanswered: 0 };
    for (let i = 0; i < questions.length; i++) {
      console.log(questions[i]);
      if (topicsMap[questions[i].topic]) {
        topicsMap[questions[i].topic].total += 1;
        if (questions[i].right_answer === questions[i].chosen_answer) {
          topicsMap[questions[i].topic].correct += 1;
        } else if (questions[i].chosen_answer === null) {
          topicsMap[questions[i].topic].unanswered += 1;
        }
        else {
          topicsMap[questions[i].topic].incorrect += 1;
        }
      } else {
        topicsMap[questions[i].topic] = { correct: 0, incorrect: 0, total: 1, unanswered: 0 };
        if (questions[i].right_answer === questions[i].chosen_answer) {
          topicsMap[questions[i].topic].correct += 1;
        } else if (questions[i].chosen_answer === null) {
          topicsMap[questions[i].topic].unanswered += 1;
        }
        else {
          topicsMap[questions[i].topic].incorrect += 1;
        }
      }
      topicsMap["total"].total += 1;
      if (questions[i].right_answer === questions[i].chosen_answer) {
        topicsMap["total"].correct += 1;
      } else if (questions[i].chosen_answer === null) {
        topicsMap["total"].unanswered += 1;
      }
      else {
        topicsMap["total"].incorrect += 1;
      }
      
      const qRef = doc(db, 'training_sessions', tid);
      await setDoc(qRef, { topic_wise_stats: topicsMap }, { merge: true });
      console.log(topicsMap);
    }
    setLoading(false); // Set loading to false
    window.location.href = `/training-session-detail/${tid}`;
  };

  const handleAccountClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const [selectedOption, setSelectedOption] = useState('');

  const prevQn = async () => {
    await setCardIdx(cardIdx - 1);
  }

  const nextQn = async () => {
    await setCardIdx(cardIdx + 1);
  }

  const makePayment = async (course_uid) => {
    const stripe = await loadStripe("pk_test_51NVzOHSEccTJVT5G27q5UDJ7BLFW5HHqUqnKalMTMwqWaObXrSOvugGy42IQlT97LEVAwDPOlRoF1dPtOAs33vU500CiMdEqJ4");
    const body = { product };
    const headers = {
      "Content-Type": "application/json",
    };

    const response = await fetch(
      "http://localhost:8000/api/create-checkout-session",
      {
        method: "POST",
        headers: headers,
        body: JSON.stringify(body),
      }
    );

    const session = await response.json();
    let items = [
      {
        id: 1,
        title: 'UAE AIR LAW AND OPERATIONAL PROCEDURES GCAA',
        imageUrl: 'https://via.placeholder.com/150',
      },
    ]
    localStorage.setItem('items', JSON.stringify(items));

    const docRef = await addDoc(collection(db, "subscriptions"), {
      user_uid: currentUser.uid,
      course_uid: course_uid,
      subscribed_at: "2021-10-10",
      is_active: true,
      title: 'UAE AIR LAW AND OPERATIONAL PROCEDURES GCAA',
      imageUrl: 'https://via.placeholder.com/150'
    });

    const result = stripe.redirectToCheckout({
      sessionId: session.id,
    });

    if (result.error) {
      console.log(result.error);
    }
  };

  const [items, setItems] = useState([]);

  return (
    <>
      {/* Header */}
      <HeaderComponent
        handleAccountClick={handleAccountClick}
        handleMenuClose={handleMenuClose}
        anchorEl={anchorEl}
      />

      {/* SideNavbar */}
      <SideNavbarComponent />

      {/* Content */}
      <Container sx={{ flexGrow: 1, display: 'flex' }}>
        <Box sx={{ flex: 1 }}>
          <Paper sx={{ p: 2, height: '85vh', borderRadius: 0, paddingTop: '30px !important' }}>
            {loading ? ( // Show loader if loading is true
              <CircularIndeterminate />
            ) : (
              <div className="CardList" style={{ marginLeft: '20px' }}>
                <div className="Breadcrumbs" >
                  {breadcrumbs.map((breadcrumb, index) => (
                    <React.Fragment key={index}>
                      <span className={index === breadcrumbs.length - 1 ? 'ActiveBreadcrumb' : 'Breadcrumb'}>
                        {breadcrumb}
                      </span>
                      {index !== breadcrumbs.length - 1 && <span className="BreadcrumbSeparator">/</span>}
                    </React.Fragment>
                  ))}
                </div>
                <div className="Column Column1">
                  <div className="SubCard" key={questions[cardIdx]?.id}>
                    <div className="CardTitle">Question #{cardIdx + 1}</div>
                    <hr />
                    <div className="CardTitle"><p>{questions[cardIdx]?.text}</p></div>
                    <div className="RadioButtons">
                      <label className="RadioButton">
                        <input
                          type="radio"
                          name="radAnswer"
                          value={0}
                          checked={questions[cardIdx]?.chosen_answer == 0}
                          onChange={event => handleOptionChange(event, questions[cardIdx])}
                        />
                        {questions[cardIdx]?.op_a}
                      </label>
                      <label className="RadioButton">
                        <input
                          type="radio"
                          name="radAnswer"
                          value={1}
                          checked={questions[cardIdx]?.chosen_answer == 1}
                          onChange={event => handleOptionChange(event, questions[cardIdx])}
                        />
                        {questions[cardIdx]?.op_b}
                      </label>
                      <label className="RadioButton">
                        <input
                          type="radio"
                          name="radAnswer"
                          value={2}
                          checked={questions[cardIdx]?.chosen_answer == 2}
                          onChange={event => handleOptionChange(event, questions[cardIdx])}
                        />
                        {questions[cardIdx]?.op_c}
                      </label>
                      <label className="RadioButton">
                        <input
                          type="radio"
                          name="radAnswer"
                          value={3}
                          checked={questions[cardIdx]?.chosen_answer == 3}
                          onChange={event => handleOptionChange(event, questions[cardIdx])}
                        />
                        {questions[cardIdx]?.op_d}
                      </label>
                    </div>
                    <div className="CardFooter">
                      <div className="CardLink">{questions[cardIdx]?.link}</div>
                    </div>
                  </div>
                </div>

                <div className="Column Column2">
                  <Pagination onChange={handleChange} style={{ marginTop: 50 }} count={questions.length} variant="outlined" color="primary" shape="rounded" showFirstButton showLastButton />
                  { tid !== "4vgV1cTgXi5hm00Udk2k" && <button style={{ marginTop: 30 }} className='subscribe' onClick={handleSubmit}>Submit</button>}
                  { tid === "4vgV1cTgXi5hm00Udk2k" && <button style={{ marginTop: 30 }} className='subscribe' onClick={() => makePayment(tid)}>Subscribe</button>}
                </div>
              </div>
            )}
          </Paper>
        </Box>
      </Container>

      {/* Footer */}
      <FooterComponent />
    </>
  );
};

export default TrainingSessionPage;
  

